<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-orange py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Password Reset Confirm</small>
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    :class="error != '' ? 'error' : ''"
                    name="Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    :type="inputType"
                    placeholder="Previous Password"
                    ref="passwordinputHash"
                    v-model="passwordHash"
                    disabled
                  >
                  </base-input>
                  <ValidationProvider
                    name="Password"
                    rules="required|password:@confirm|min:6"
                    v-slot="{ errors }"
                  >
                    <base-input
                      alternative
                      class="mb-3"
                      :class="error != '' ? 'error' : ''"
                      name="Password"
                      prepend-icon="ni ni-lock-circle-open"
                      :type="inputType"
                      placeholder="New Password"
                      v-model="password"
                    >
                    </base-input>
                    <div
                      class="error"
                      style="
                        height: 30px;
                        color: #e45649;
                        margin-top: -10px;
                        font-size: 13px;
                      "
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    name="confirm"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <base-input
                      alternative
                      class="mb-3"
                      :class="error != '' ? 'error' : ''"
                      prepend-icon="ni ni-lock-circle-open"
                      :type="inputType"
                      placeholder="Confirm Password"
                      ref="confirmPasswordinput"
                      v-model="confirmPassword"
                    >
                    </base-input>
                    <div class="show-password">
                      <span class="icons">
                        <i
                          class="fa fa-eye"
                          v-if="!showPassword"
                          @click="toggleShowPassword()"
                        ></i>
                        <i
                          class="fa fa-eye-slash"
                          v-if="showPassword"
                          @click="toggleShowPassword()"
                        ></i>
                      </span>
                    </div>
                    <div
                      class="error"
                      style="
                        height: 30px;
                        color: #e45649;
                        margin-top: -10px;
                        font-size: 13px;
                      "
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      >Submit
                    </base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <modal @close="closeModal" :show="isModalVisible">
        <div class="success-message" v-if="successState">
          <div class="icon-wrapper">
            <i class="fas fa-check"></i>
          </div>
          <p>Please check your email for further instructions!</p>
        </div>
        <div class="success-message" v-if="isLoading">
          <spinner></spinner>
        </div>

        <div class="error-message" v-if="failState">
          <div class="icon-wrapper">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <p>Something went wrong, please try again !</p>
        </div>

        <b-button
        @click="closeModal"
        type="button"
        variant="primary cancel"
        >Close</b-button
      >

      </modal>
    </b-container>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue";
import { extend } from "vee-validate";

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      password: "",
      confirmPassword: "",
      passwordHash: "",
      showPassword: false,
      error: "",
      inputType: "password",
      token: "",
      isModalVisible: false,
      failState: false,
      successState: false,
    };
  },
  mounted() {
    this.passwordHash = this.getHashPassword();
    this.token = this.getToken();
  },
  methods: {
    getToken() {
      return this.$route.query.token;
    },
    getHashPassword() {
      return this.$route.query.hash;
    },
    onSubmit() {
      this.isLoading = this.isModalVisible = true;
      let token = this.token;
      let password = this.password; //create Hash of password here
      this.$store
        .dispatch("accounts/resetPassword", { token, password })
        .then((res) => {
         this.isLoading = this.failState = false;
          this.successState = true;
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          this.isLoading = this.successState = false;
          this.failState = true;
          // Show error message
          this.error = this.$store.getters["accounts/status"];
          console.log(err);
        });
    },
    toggleShowPassword() {
      // Toggle showPass property
      this.showPassword = !this.showPassword;
      // Change input field type depending on show password property
      if (this.showPassword) {
        // Show password text
        this.inputType = "text";
      } else {
        // Hide password text
        this.inputType = "password";
      }
    },

    closeModal(){
      this.isModalVisible = this.failState = this.successState = false;
    },
  },
};
</script>

<style>
.show-password {
  position: absolute;
  right: 60px;
  top: 173px;
  z-index: 99999;
  cursor: pointer;
}

.show-password span {
  font-size: 16px;
  color: #adb5bd;
}

.error .input-group {
  border: 1px solid rgb(228, 86, 73);
}

.success-message {
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.success-message p {
  color: #f7941d;
  margin-top: 15px;
}
</style>
