<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-orange py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Sign in</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              :class="error != '' ? 'error'  : ''"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              :class="error != '' ? 'error'  : ''"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              :type="inputType"
                              placeholder="Password"
                              ref="passwordinput"
                              v-model="password">
                  </base-input>
                  <div class="show-password">
                    <span class="icons">
                      <i class="fa fa-eye" v-if="!showPassword" @click="toggleShowPassword()"></i>
                      <i class="fa fa-eye-slash" v-if="showPassword" @click="toggleShowPassword()"></i>  
                    </span>
                  </div>
                  <div class="error" v-if="error != ''" style="height: 30px; color: #e45649;margin-top: -10px;font-size: 13px;">
                    <span>Email or password is incorrect. Please try again.</span>
                  </div>
                  <b-form-checkbox v-model="rememberMe">Remember me</b-form-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/forgot-password" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  export default {
    data() {
      return {
          email: '',
          password: '',
          rememberMe: false,
          showPassword: false,
          error: '',
          inputType: 'password'
      };
    },
    computed: {
      
    },
    methods: {
      onSubmit() {
        let email = this.email;
        let password = this.password; //create Hash of password here
        this.$store.dispatch('accounts/login', { email, password })
          .then(() => this.$router.push('/dashboard'))
          .catch(err => {
            console.log(err.message);
            // Show error message
            this.error = this.$store.getters['accounts/status'];
            // Remove error message from page
            // setTimeout(() => {
            //   this.error = '';
            // }, 4500)
          })
      },
      toggleShowPassword() {
        // Toggle showPass property
        this.showPassword = !this.showPassword;
        // Change input field type depending on show password property
        if(this.showPassword) {
          // Show password text
          this.inputType = "text"
        } else {
          // Hide password text
          this.inputType = "password"
        }
      }
    }
  };
</script>


<style>
 .show-password {
    position: absolute;
    right: 60px;
    top: 173px;
    z-index: 99999;
    cursor: pointer
 }

 .show-password span {
   font-size: 16px;
   color: #adb5bd;

 }

 .error .input-group {
   border: 1px solid rgb(228, 86, 73);
 }
</style>