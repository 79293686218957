<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-orange py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Forgot Password</small>
              </div>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <ValidationProvider
                    name="Email"
                    :rules="{required: true, email: true}"
                    v-slot="{ errors }"
                  >
                    <base-input
                      alternative
                      class="mb-3"
                      name="email"
                      placeholder="Email"
                      v-model="email"
                    >
                    </base-input>
                    <div
                      class="error"
                      style="
                        height: 30px;
                        color: #e45649;
                        margin-top: -10px;
                        font-size: 13px;
                      "
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      >Submit
                    </base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
        <b-col cols="6">
            <router-link to="/login" class="text-light"><small>Login</small></router-link>
        </b-col>
        </b-row>
        </b-col>
      </b-row>
      <modal @close="closeModal" :show="isModalVisible">
        <div class="success-message" v-if="successState">
          <div class="icon-wrapper">
            <i class="fas fa-check"></i>
          </div>
          <p>Please check your email for further instructions!</p>
        </div>
        <div class="success-message" v-if="isLoading">
          <spinner></spinner>
        </div>

        <div class="error-message" v-if="failState">
          <div class="icon-wrapper">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <p>Something went wrong, please try again !</p>
        </div>

        <b-button
        @click="closeModal"
        type="button"
        variant="primary cancel"
        >Close</b-button
      >

      </modal>
    </b-container>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue";
import modal from '@/components/Modal.vue';

export default {
  components: {
    Spinner,
    modal
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      email: "",
      failState: false,
      successState: false,
      err:null,
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = this.isModalVisible = true;
      let email = this.email;

      this.$store
        .dispatch("accounts/forgotPassword", email)
        .then(() => {
          this.isLoading = this.failState = false;
          this.successState = true;
        })
        .catch((err) => {
          this.isLoading = this.successState = false;
          this.failState = true;
          this.err = err;
        });
    },

    closeModal(){
      this.isModalVisible = this.failState = this.successState = false;
    },

  },
};
</script>

<style>
.show-password {
  position: absolute;
  right: 60px;
  top: 173px;
  z-index: 99999;
  cursor: pointer;
}

.show-password span {
  font-size: 16px;
  color: #adb5bd;
}

.error .input-group {
  border: 1px solid rgb(228, 86, 73);
}

.success-message, .error-message {
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.success-message p {
  color: #f7941d;
  margin-top: 15px;
}

.error-message p {
  color: crimson;
  margin-top: 15px;
}
</style>
